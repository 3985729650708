export enum ORION_DOMAIN_LIST_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum ORION_SEGMENT_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum DOMAIN_LIST_SCOPE {
  GLOBAL = 1,
  ADVERTISER = 2,
  SEGMENT = 3,
}

export enum ORION_EMBEDDING_ACTION {
  INSERT = 'Insert',
  INSERT_GPT = 'GPT',
  INSERT_URL = 'Insert URL',
  UPDATE = 'Update',
  REMOVE = 'Remove',
}

export enum ORION_ATTRIBUTE_ACTION {
  INSERT = 'Insert',
  REPLACE = 'Replace',
  REMOVE = 'Remove',
  CLEAR_CATEGORY = 'Remove All From Category',
}

export enum PULSE_ACTION {
  LIKE = 'like',
  DISLIKE = 'dislike',
  EXCLUDE = 'exclude',
}

export enum ORION_SEGMENT_TYPE {
  RELEVANCE = 1,
  CTV = 4,
}

export enum ORION_DATA_CENTER {
  US = 1,
  EU = 2,
}

export enum ORION_DOMAIN_LIST_UPLOAD_STATE {
  PENDING = 1,
  SUCCEEDED = 4,
  FAILED = 99,
}

export enum ORION_DOMAIN_LIST_DOWNLOAD_STATE {
  QUEUED = 1,
  PROCESSING = 2,
  SUCCEEDED = 3,
  FAILED = 4,
}

export enum ORION_CREATIVE_TYPE {
  DISPLAY = 1,
  VIDEO = 2,
  NATIVE = 3,
  CTV = 4,
  DYNAMIC_CTV = 5,
}

export enum ORION_DEVICE_TYPE {
  UNKNOWN = 0,
  DESKTOP = 1,
  MOBILE = 2,
  CONNECTED_TV = 3,
}

export enum ORION_EMBEDDING_FILTER_TYPE_ID {
  PROMPT = 1,
  LIKED = 2,
  DISLIKED = 3,
  GPT = 4,
}

export enum TITAN_ADVERTISER_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum TITAN_CAMPAIGN_STATE_ID {
  CREATED = 1,
  GATHERING_DATA = 2,
  MODEL_TRAINING = 3,
  SETTING_UP = 4,
  LIVE_PENDING = 5,
  LIVE = 6,
  UNHEALTHY = 7,
  OFFLINE = 8,
  ARCHIVED = 99,
}

export enum TITAN_DELIVERY_SOURCE_ID {
  DSP = 1,
  SSP = 2,
  ADSERVER = 3,
  INTERNAL = 4,
  EXTERNAL = 5,
}

export enum TITAN_CAMPAIGN_OPTION_ID {
  EDIT = 1,
  TURN_ACTIVE = 2,
  TURN_OFFLINE = 3,
}

export enum TITAN_ADVERTISER_STATE {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum TITAN_ORGANIZATION {
  COGNITIV = 1,
  ADFORM = 236,
  SALES = 34,
  ADFORM_SALES = 241,
}

export enum TITAN_CREATIVE_TYPE_ID {
  DISPLAY = 1,
  VIDEO = 2,
  NATIVE = 3,
  CTV = 4,
  DYNAMIC_CTV = 5,
}

export enum TITAN_INVENTORY_SOURCE_ID {
  MAGNITE = 10,
  PUBMATIC = 8,
  XANDR = 7,
  INDEX = 4,
}

export enum TITAN_PRODUCT_TYPE_ID {
  CORE = 1,
  CURATION = 2,
  CONTEXTUAL = 3,
}

export enum TITAN_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum TITAN_ADVERTISER_ACTION {
  EDIT = 1,
  EDIT_TEAM = 2,
  ACTIVATE = 3,
  TURN_OFFLINE = 4,
  ARCHIVE = 5,
  UNARCHIVE = 6,
  FREQUENCY_CAPS = 7,
}
